@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

/* Adjust global line height to accommodate both fonts */
body {
    font-family: $serif;
    line-height: 1.7;

    /* Optional: improve Korean text word breaking 
    word-break: keep-all; */
}

/* Specifically for headings which often use sans-serif */
h1, h2, h3, h4, h5, h6 {
    line-height: 2;
}

a {
    text-decoration: underline;
    text-underline-offset: 3px; /* Adjust as needed */
}

p sub {
    vertical-align: baseline;
    position: relative;
    top: 0em;
}

ul sub {
    vertical-align: baseline;
    position: relative;
    top: 0em;
}
figcaption {
    color:#6F6E69;
    font-family: $sans-serif;
    margin-top: -1em;
    margin-bottom: 1em;

    sub {
    vertical-align: baseline;
    position: relative;
    top: 0em;
    }
}

blockquote {
    margin-block: 0em;
    margin-inline-end: 1em;
    padding-inline: 1em;
    font-style: normal; /* Removes italics */
    border-inline-start: 0.25em solid #CECDC3; /* Custom left border color */
    color: #6F6E69;
}

cite {
    font-style: normal; /* Removes italics from citation */
    color: #6F6E69;
  
    &::before {
      content: "\2014"; /* Keeps the dash */
      padding-inline-end: 0px;
    }
}

mark {
    background: #CECDC3; /* Change to your preferred color */
}

.more {
  font-size: 0.9rem;
  color: #6F6E69;
}

.more a {
  color: #6F6E69;
}

.more a:hover {
  color: $primary-color;
}

.archive-page {
    max-width: 700px;
    margin: 2rem auto;
    padding: 0 1rem;
}
  
.archive-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
.archive-item {
    display: flex;
    align-items: baseline;
    gap: 1.5rem; /* Space between date and title */
    margin-bottom: 0.75rem;
    flex-wrap: wrap; /* Allow wrapping on small screens */
}
  
.archive-date {
    font-size: 0.8rem;
    color: #6F6E69;
    /* white-space: nowrap; */
    flex-shrink: 0;
    font-family: monospace;
    min-width: 90px; /* Fixed width for dates */
    /* text-align: right; /* Aligns date to the right */
}
  
.archive-title {
    flex: 1;
    font-size: 1rem;
    text-decoration: none;
    color: #100F0F;
    transition: color 0.2s;
    flex-grow: 1; /* Ensures title takes up the remaining space */
}
  
.archive-title:hover {
    color: $primary-color; /* Accent color on hover */
}

/* Mobile-specific adjustments */
@media screen and (max-width: 576px) {
    .archive-item {
        gap: 0.75rem; /* Reduce gap on mobile */
    }
    
    /* Optional: Make dates and titles stack on very small screens */
    @media screen and (max-width: 380px) {
        .archive-item {
            flex-direction: column;
            align-items: flex-start;
            gap: 0.25rem;
        }
    }
}
  
.post-archive-link {
    margin-top: 1rem; /* Adjust spacing as needed */
    text-align: right; /* Align to the right, optional */
    font-size: 0.9rem;
    color: #6F6E69;
}
  
.post-archive-link a {
    text-decoration: underline;
    color: #6F6E69;
}
  
.post-archive-link a:hover {
    color: $primary-color;
    text-decoration: underline;
}

// Minimalistic Newsletter Form Styling
  .page__footer-newsletter {
    font-family: $sans-serif;
    padding: 0 0 0 0;
    background-color: $background-color;
  }


h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 2em 0 1em;
  line-height: 1.2;
  font-family: $header-font-family;
  font-weight: bold;
}
.page__meta {
    color: $muted-text-color;
}

.page__content
    h2 {
      margin-bottom: 1em;
}