/* Merriweather */
@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Merriweather/Merriweather-Regular.woff2') format('woff2');
 }
 @font-face {
     font-family: 'Merriweather';
     font-style: italic;
     font-weight: 400;
     font-display: swap;
     src: url('/assets/fonts/Merriweather/Merriweather-Italic.woff2') format('woff2');
 }
 @font-face {
     font-family: 'Merriweather';
     font-style: normal;
     font-weight: 700;
     font-display: swap;
     src: url('/assets/fonts/Merriweather/Merriweather-Bold.woff2') format('woff2');
 }
 @font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/Merriweather/Merriweather-BoldItalic.woff2') format('woff2');
}
  
/* Noto Serif KR */
  @font-face {
    font-family: 'Noto Serif KR';
    unicode-range: U+AC00-D7AF, U+1100-11FF, U+3130-318F, U+A960-A97F, U+D7B0-D7FF, U+002D, U+002E, U+003F, U+0022, U+0027, U+2018, U+2019, U+201C, U+201D;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Noto-KR/Serif-Regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Noto Serif KR';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/Noto-KR/Serif-Bold.woff2') format('woff2');
  }

/* Noto Sans KR */
@font-face {
     font-family: 'Noto Sans KR';
     font-style: normal;
     font-weight: 400;
     font-display: swap;
     src: url('/assets/fonts/Noto-KR/Sans-Regular.woff2') format('woff2');
   }
   @font-face {
     font-family: 'Noto Sans KR';
     font-style: normal;
     font-weight: 700;
     font-display: swap;
     src: url('/assets/fonts/Noto-KR/Sans-Bold.woff2') format('woff2');
   }